<template>
	<BaseLayout>
		<Toolbar @clickLeft="$router.push('/account')">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ $t('setting.settings') }}</p>
		</Toolbar>
		<div class="inline-flex w-full flex-col gap-5 p-5">
			<!-- <div class="grid grid-cols-8 items-center gap-5 rounded-2xl bg-light-grey p-5">
				<div class="col-span-6">
					<label class="text-lg text-primary">{{ $t('setting.pushNotifications') }}</label>
					<p class="text-xs text-secondary">{{ $t('setting.receiveLatestPromotions') }}</p>
				</div>
				<div class="relative col-span-2 mx-auto mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
					<input :disabled="isLoading" :checked="customer.appNotification" :name="customer.appNotification" @click="onToggle('app', $event.target.checked)" type="checkbox" class="absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 border-gray-300 bg-white checked:right-0 checked:border-primary" />
					<label class="block h-6 cursor-pointer overflow-hidden rounded-full bg-gray-300" :class="customer.appNotification ? 'bg-primary' : 'bg-gray-300'" :for="customer.appNotification"></label>
				</div>
			</div> -->

			<div v-if="customer.phoneNumber" class="grid grid-cols-8 items-center gap-5 rounded-2xl bg-light-grey p-5">
				<div class="col-span-6">
					<label class="text-lg text-primary">{{ $t('setting.smsNotifications') }}</label>
					<p class="text-xs text-secondary">{{ $t('setting.receiveSMS') }}</p>
				</div>
				<div class="relative col-span-2 mx-auto mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
					<input :disabled="isLoading" :checked="customer.isSmsEnabled" :name="customer.isSmsEnabled" @click="onToggle('Sms', $event.target.checked)" type="checkbox" class="absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 border-gray-300 bg-white checked:right-0 checked:border-primary" />
					<label class="block h-6 cursor-pointer overflow-hidden rounded-full" :class="customer.isSmsEnabled ? 'bg-primary' : 'bg-gray-300'" :for="customer.isSmsEnabled"></label>
				</div>
			</div>

			<div v-if="customer.emailAddress" class="grid grid-cols-8 items-center gap-5 rounded-2xl bg-light-grey p-5">
				<div class="col-span-6">
					<label class="text-lg text-primary">{{ $t('setting.emailNotifications') }}</label>
					<p class="text-xs text-secondary">{{ $t('setting.receiveEmail') }}</p>
				</div>
				<div class="relative col-span-2 mx-auto mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
					<input :disabled="isLoading" :checked="customer.isEmailEnabled" :name="customer.isEmailEnabled" @click="onToggle('Email', $event.target.checked)" type="checkbox" class="absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 border-gray-300 bg-white checked:right-0 checked:border-primary" />
					<label class="block h-6 cursor-pointer overflow-hidden rounded-full" :class="customer.isEmailEnabled ? 'bg-primary' : 'bg-gray-300'" :for="customer.isEmailEnabled"></label>
				</div>
			</div>

			<div v-if="customer.phoneNumber" class="grid grid-cols-8 items-center gap-5 rounded-2xl bg-light-grey p-5">
				<div class="col-span-6">
					<label class="text-lg text-primary">{{ $t('setting.whatsappNotifications') }}</label>
					<p class="text-xs text-secondary">{{ $t('setting.receiveWhatsapp') }}</p>
				</div>
				<div class="relative col-span-2 mx-auto mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
					<input :disabled="isLoading" :checked="customer.isWhatsappEnabled" :name="customer.isWhatsappEnabled" @click="onToggle('Whatsapp', $event.target.checked)" type="checkbox" class="absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 border-gray-300 bg-white checked:right-0 checked:border-primary" />
					<label class="block h-6 cursor-pointer overflow-hidden rounded-full" :class="customer.isWhatsappEnabled ? 'bg-primary' : 'bg-gray-300'" :for="customer.isWhatsappEnabled"></label>
				</div>
			</div>

			<div class="grid grid-cols-8 items-center gap-5 rounded-2xl bg-light-grey p-5">
				<div class="col-span-5">
					<label class="text-lg text-primary">{{ $t('setting.language') }}</label>
				</div>
				<div class="col-span-3">
					<div class="flex w-24 justify-center rounded-xl bg-white p-2">
						<img class="mr-1" :src="require(`../assets/flags/${locale}.svg`)" alt="" />
						<div class="relative inline-block w-full">
							<select class="appearance-none bg-transparent pr-4 text-right outline-none" v-model="locale" v-on:change="setLang($event.target.value)">
								<option v-for="(item, index) in optionsLang" :key="index" :value="item.value">{{ item.text }}</option>
							</select>
							<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
								<svg class="h-4 w-4 fill-current" viewBox="0 0 20 20">
									<path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<popup v-if="showDeletePopup" :title="$t('account.deleteAccountTitle')" @close="showDeletePopup = false" @submit="deleteAccount" :confirmText="$t('account.deleteAccountButton')">
			<p class="text-xs text-secondary">{{ $t('account.deleteAccountDescription') }}</p>
		</popup>
		<Footer>
			<Button type="submit" :loading="isLoading" :disabled="isLoading" @click="showDeletePopup = true">{{ $t('account.deleteAccountTitle') }}</Button>
		</Footer>
	</BaseLayout>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useSharedStore } from '@/store/shared'
import { useCustomerStore } from '@/store/customer'
import { customerApi } from 'gox-sdk'

export default {
	setup() {
		const router = useRouter()
		const showDeletePopup = ref(false)
		const store = useStore()
		const { locale } = useI18n()
		const { customerSignOut } = useCustomerStore()
		const { currentCustomer } = storeToRefs(useCustomerStore())
		const customer = currentCustomer.value
		// const customer = ref({ ...store.state.customer })

		const optionsLang = ref([
			{ text: '繁體', value: 'TC' },
			{ text: '簡体', value: 'SC' },
			{ text: 'ENG', value: 'EN' },
		])

		const { setLang } = useSharedStore()

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		async function onToggle(type, value) {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				customer['is' + type + 'Enabled'] = value
				await customerApi.updateCustomer(customer.id, customer)
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function deleteAccount() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await customerApi.deleteCustomer(customer.id)
				await customerSignOut()
				showDeletePopup.value = false
				router.push('/launch')
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		return {
			deleteAccount,
			showDeletePopup,
			setLang,
			locale,
			customer,
			error,
			isLoading,
			onToggle,
			optionsLang,
		}
	},
}
</script>
